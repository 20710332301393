html {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-family: 'Poppins', sans-serif;
    height: 100%;
}

body {
    min-height: 100%;
    background: rgba(217, 217, 217, 0.14);
}

.main-nav {
    display: flex;
    flex-direction: column;
}

/* .logo {
    width: 147px;
    height: 92px;
} */


.main-nav ul {
    list-style-type:  none;
    display: flex;
    flex-direction: column;
}


@media(min-width:768px) {
    .logo {
        width: 335px !important;
        /* height: 60px; */
    }

    .main-nav {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .main-nav ul {
        flex-direction: row;
        padding: 15px;
    }
}
.main-nav ul li{
    padding: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1em;
}

.nav-link,
.nav-link:hover,
.nav-link:focus,
.nav-link:visited,
.nav-link:active{
    text-decoration: none;
    color: #84287D;
}

.contact-us,
.contact-us:hover,
.contact-us:focus {
    color: #FFFFFF;
    background: #CE1D74;
    border-radius: 8px;
    padding: 10px 20px;
    text-decoration: none;
}

@media (min-width: 350px) and (max-width: 991px) {
  
    .order-now-btn {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        background: #CE1D74;
    
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .main-nav ul li {
        padding: 5px;
        letter-spacing: 0em;
        text-align: right;
    }
    .logo {
        width: 180px !important;
    }
    .order-now-btn {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        background: #CE1D74;
    
    }
}

/* **** Mobile menu **** */
.hamburger {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0px;
    background: #FFFFFF;
    padding-top: 2%;
    z-index: 1;
}

@media (min-width: 768px) {
    .hamburger{
        display: none;
    }
}

.ham-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-logo {
    width: 230px;
}

ul {
    list-style-type: none;
}

ul li {
    margin-top: 10px;
}

.mobile-nav-link {
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #84287D;
}

.close {
    font-size: 24px;
    cursor: pointer;
}

.navigation {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw !important;
    background: #FFFFFF;
    height: 150px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    z-index: 999;
}


.video-mic {
    /* width: 100vw !important; */
    min-height: calc(100vh - 150px);
    clip-path: polygon(0% 0%, 99.9% 0%, 100% 100%, 0% 90%);
    background: #84287D;
    padding: 100px 10px ;
}

@media (max-width:767px){
    .navigation{
        display: none;
    }
}

.vid-section {
    display: flex;
    flex-direction: column;
    background-image: url('../images/video-film.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
}

.video-text {
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 35px;
    background-image: url('../images/Vector4.png');
    background-position: 30% 33%;
    background-repeat: no-repeat;
    background-size: 200px 35px;
}

.text-title,
.text-content {
    margin-bottom: 35px;
}

.text-title {
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
}

.text-content {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.get-started {
    font-size: 20px;
    font-weight: 600;
    color: #84287D;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
}


@media(min-width: 768px){
    .video-mic {
        min-height: calc(100vh - 150px);
        padding: 300px 10px ;
    }
    .vid-section{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-image: url('../images/video-film.png');
        background-position: left;
    }
    
    .text-title {
        font-size: 42px;
        line-height: 48px;
    }

    .text-content {
        font-size: 25px;
        line-height: 30px;
    }

    .video-text{
        width: 45%;
        text-align: left;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 518px 114px;
    }
    .amico {
        width: 45%;
        height: 482px;
    }
}

.benefits-section {
    padding-bottom: 100px;
}

.page-heading-section,
.why-choose-header,
.common-questions-header,
.packages-header,
.benefits-header,
.about-header {
    background-image: url('../images/Vector3.png');
    background-repeat: no-repeat;
    background-size: 148px 22px;
}

.benefits-header {
    background-position: 50% 15%;
}

.packages-header {
    background-position: 50% 12%;
}

.about-header {
    background-position: 50% 32%;
}

.common-questions-header {
    background-position: 50% 8%;
}

.process-header {
    background-position: 50% 32%;
}

.portfolio-header {
    background-position: 50% 22%;
}

.page-heading-section {
    background-position: 50% 16%;
}

.section-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #372E7C;
    margin-bottom: 22px;
    text-align: center; 
}

.benefits-header h1  {
    text-align: center;
}


.packages-header p,
.benefits-header p {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    color: #666666;
    text-align: center;
}

.process-container {
    margin-top: 27px;
}

.get-in-touch-container {
    background: #FFC2DF;
}

@media (min-width: 768px){
    .process-container {
        margin-top: 100px;
    }

    .packages-header,
    .benefits-header,
    .about-header{
        background-size: 327px 44px;
    }

    .packages-header {
        background-position: 50% 45%;
    }

    .benefits-header {
        background-position: 50% 35%;
    }

    .about-header {
        background-position: 0 55%;
    }

    .common-questions-header {
        background-position: 50% 28%;
    }

    .why-choose-header {
        background-position: 50% 30%;
    }

    .page-heading-section {
        background-size: 0;
    }
    
   .section-header  {
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 50px;
    }
    
    .packages-header p,
    .benefits-header p {
        font-size: 20px;
        line-height: 30px;
        text-align: left;
    }
}

.collapse-content {
    display: flex;
    flex-direction: column;
}

.watching-image {
    text-align: center;
}

.man-watching {
    width: 211px;
    height: 211px;
    margin-left: auto;
}

.accordion {
    margin-bottom: 20px;
}


.accordion:last-child {
    margin-bottom: 0;
}

.accordion-button
 {
    background: rgba(249, 226, 237, 0.39) !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #666666 !important;
    border:none !important;
}

.accordion-button:focus
{
    background: #F9E2ED !important;
}


.accordion-button:not(.collapsed)::after {
    color: #FFFFFF;
}

.accordion-body {
    background: #F9E2ED !important;
}

@media(min-width: 768px) {
    .benefits-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .watching-image {
        text-align: left;
    }
    .man-watching {
        height: 520px;
        width: 520px;
    }

    .collapse-content {
        width: 45%;
    }
}

.why-get-section {
    margin: 37px 0;
    text-align: center;
}

.why-get-btn {
    background: #CE1D74;
    color: #FFFFFF;
    border: 2px solid #CE1D74;
    
}

@media(min-width:768px) {
    .why-get-section {
        /* margin: 88px 0; */
        text-align: left;
    }
}

.grayed-bg {
    background: rgba(217, 217, 217, 0.14);
}

.choice-card {
    background: #FFFFFF;
    box-shadow: 0px -1px 48px 11px rgba(206, 29, 116, 0.03);
    height: 68px;
    margin: 10px 0;
}

.choice-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.choice-vector {
    width: 31px;
    height: 31px;
    margin-right: 10px;
}

.choice-title,
.choice-text {
    margin: 0;
}

.choice-title {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #372E7C;
}

.choice-text {
    font-weight: 300;
    font-size: 11px;
    line-height: 14px;

}

@media(min-width: 767px) { 
    .choice-section{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .choice-card {
        width: 48%;
        height: 180px;
        margin: 20px 0;
        padding: 13px;
    }

    .choice-vector {
        width: 76px;
        height: 76px;
        margin-right: 22px;
    }

    .choice-title,
    .choice-text {
        margin: 8px;
        line-height: 1.3rem;
    }
        
    .choice-title {
        font-weight: 500;
        font-size: 1.2rem;
    }
    
    .choice-text {
        font-weight: 400;
        font-size: .9rem;
    }
}

.packages-container {
    margin: 30px 0;
}

.choose-package {
    display: flex;
    flex-direction: column;
}

.package-get,
.single-package {
    box-shadow: 2px 0px 7px 8px rgba(55, 46, 124, 0.02);
    width: 240px;
    margin: 10px auto;
}


.package-get {
    padding: 16px;
}
    

.whiteboard {
    background: rgba(249, 226, 237, 0.39);
}

.two-d-animation {
    background: #F9E2ED;
}

.lite-two-d-animation {
    background: #FFC2DF;
}

.single-package-header {
    text-align: center;
}

.screenshot {
    width: 186px;
    height: 103px;
    margin-left: auto;
    margin: 24px 0;
}

.single-title,
.single-price {
    font-weight: 600;
    letter-spacing: 0.12em;
    color: #3E2E7F;
}

.single-title {
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
}

.single-price {
    font-size: 19px;
    line-height: 28px;
}

.package-ul {
    margin-bottom: 20px;
}

.single-package ul li  {
    /* font-weight: 500; */
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.07em;
    color: #3E2E7F;
    list-style-image: url('../images/Group74.png');
    margin-left: 20px;
}

.package-get {
    list-style-image: url('../images/Vector102.png');
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.07em;
    color: #666666;
}

.strike {
    text-decoration: line-through;
}

.choose-form {
    text-align: center;
}

.choose-form select,
.order-now-btn {
    width: 180px;
    height: 32px;
    border: 1px solid #CE1D74;
    border-radius: 4px;
}
.choose-form select {
    margin: 16px auto;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    color: #CE1D74;
    background: transparent;
}

.order-now-section {
    margin: 8px 0;
    text-align: center;
}

.order-now-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    background: #CE1D74;

}

@media(min-width:768px){
    .choose-package {
        flex-direction: row;
    }

    .single-package{
        width: 30%;
        height: 834px;
    }

        
    .package-get{
        margin: 10px 0 10px 10px;
    }

    .screenshot {
        width: 80%;
        height: 12rem;
    }

    .single-title {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 36px;
    }

    .single-price {
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 36px;
    }

    .package-ul {
        margin-bottom: 120px;
    }

    .single-package ul li {
        font-size: 1rem;
        line-height: 22px;
    }

    .choose-form select {
        font-size: 16px;
        width: 80%;
        height: 37px;
    }

    .order-now-btn {
        width: 80%;
        height: 37px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }
}

.process-section {
    /* margin-top: 33px; */
    /* margin-bottom: 33px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.single-process-card {
    display: flex;
    justify-content: flex-start;
    width: 309px;
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0px -1px 48px 11px rgba(55, 46, 124, 0.02);
    padding: 4px;
    margin: 10px 0;
}

.process-vector {
    width: 51px;
    height: 51px;
    margin-right: 10px;
}

.process-title {
    font-weight: 500;
    font-size: 12px;
    /* line-height: 15px; */
    letter-spacing: 0.04em;
    color: #372E7C;
    margin: 0;
}

.process-text {
    font-weight: 400;
    font-size: 11px;
    /* line-height: 14px; */
    color: #666666;
}

@media (min-width: 426px) and (max-width: 767px){
    .single-process-card {
        width: 90%;
        height: 100px;
        padding: 4px;
        margin: 5px 0;
    }
    .order-now-btn {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        background: #CE1D74;
    
    }
    

    .process-title {
        font-size: .9rem;
    }

    .process-text {
        font-size: .8rem;
    }

    .choice-card {
        padding: 7px;
    }

    .choice-content {
        align-items: flex-start;
    }

    .choice-vector {
        width: 3rem;
        height: 3rem;
    }

    .choice-title {
        font-size: .8rem;
        line-height: 1rem;
        /* margin: .5rem; */
    }

    .choice-text {
        font-size: .7rem;
        line-height: 1rem;
        /* margin: .5rem; */
    }
}

@media (min-width:768px) {
    .process-section{
        flex-direction: row;
        justify-content: space-between;
    }

    .single-process-card {
        width: 48%;
        height: 208px;
        padding: 40px;
    }

    .process-vector {
        width: 79px;
        height: 79px;
    }

    .process-title{
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.04em;
    }

    .process-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.04em;
    }
}


.iframe-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}

iframe {
    height: 139px;
    margin-bottom: 20px;
}

.about-us {
    text-align: center;
}


.about-container {
    background: #FAF1F5;
    padding-bottom: 20px;
}

@media (min-width: 768px) {
    
    .about-container {
        padding: 20px 100px;
    }

    .iframe-section {
        flex-direction: row;
        justify-content: space-between;

    }

    iframe {
        width: 30%;
        height: 214px;
    }

    .section-header {
        text-align: center;
    }

    .aaa {
        text-align: left;
    }

    .about-us {
        text-align: left;
    }
    
    .about-content p {
        font-size: 20px;
    }
}

.common-questions .accordion .accordion-item {
    margin-bottom: 20px;
    border: none;
}


.common-questions .accordion .accordion-item .accordion-header .accordion-button {
    border: none !important;
    background: rgba(217, 217, 217, 0.14) !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 18px !important;
    
}

.common-questions .accordion .accordion-item .accordion-body{
    background: none !important;
    font-size: 20px;
    font-weight: 400;
}

.get-in-touch-container {
    padding: 25px 39px;
    text-align: center;
}

.get-in-touch-btn-section {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.get-in-touch-container h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #372E7C;
}

.get-in-touch-btn {
    width: 230px;
    height: 34px;
    border-radius: 0;
}

@media (min-width: 768px) {
    .get-in-touch-container h1 {
        font-size: 32px;
        line-height: 48px;
    }
}

footer {
    background: #84287D;
    color: #FFFFFF;
    padding: 20px 0;
}

.footer-content {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 53px;
}

.logo-desc {
    display: flex;
    flex-direction: column;
}

/* .footer-logo {
    width: 125px;
    height: 112px;
} */

.footer-desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    margin-top: 15px;
    text-align: justify;
}

.useful-links {
    margin-top: 20px;
}

.useful-links
ul li {
    list-style-type: none;
    font-size: 12px;
}

.useful-links ul li a,
.useful-links ul li a:hover,
.useful-links ul li a:focus{
    text-decoration: none;
    color: inherit;
}

.newsletter-input,
.submit-btn {
    width: 100%;
    height: 38px;
}

.newsletter-input {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius:4px;
}

.submit-btn {
    color: #FFFFFF;
    background: #CE1D74;
    border: 1px solid #CE1D74;
    border-radius:4px;
}

.terms-container {
    padding-top: 60px;
}

@media (min-width:768px) {
    .footer-content {
        flex-direction: row;
        justify-content: space-between;
    }

    /* .logo-desc{
        flex-direction: column;
    } */

    .footer-desc {
        width: 400px;
        font-size: 20px;
        line-height: 24px;
    }

    .useful-links
    ul li {
        list-style-type: none;
        font-size: 20px;
        line-height: 20px;
    }

    .terms-container {
        padding-top: 200px;
    }
}

.copyright {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    margin-top: 10px;
    margin-left: 10px;
}

.page-heading-section{
    text-align: center;
    padding: 20px 0;
}

.page-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #372E7C;
}

.page-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #666666;
}

.page-text span {
    font-weight: 700;
}

.static-section-title,
.static-section-content {
    text-align: left !important;
}

.static-page-section ul li {
    font-size: 15px;
    list-style-type: disc;
}

.contact-header {
    background: #84287D;
    background-image: url('../images/video-film.png');
    background-repeat: no-repeat;
    background-position: 20% 70px;
    background-size: 70%;
    width: 100%;
    height: 232px;
    padding-top: 100px;
    text-align: center;
    color: #FFFFFF;
}

.contact-form-section {
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -70px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.help-you {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #372E7C;
    padding: 20px 0;
}

.question-help {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #666666;
}


.do-you-want {
    font-weight: 700;
    line-height: 18px;
    padding: 10px 0;
    color: #372E7C;
}

.icon-sec {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    padding: 0 10px;
}

.icon-sec:last-child{
    justify-content: center;
}

.icon-layer {
    padding: 19px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
}

input[type=text],
input[type=email],
input[type=number],
.contact-submit {
    width: 100%;
    height: 39px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin: 10px 0;
}

.contact-submit {
    background: #CE1D74;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 33px;
}

.icon-text {
    font-weight: 700;
    font-size: 9px;
    line-height: 14px;
    color: #84287D;
}

.icon-text span {
    font-weight: 400;
}


.accordion-div {
    display: flex;
    flex-direction: column;
}

@media(min-width:768px) {
    .page-title{
        text-align: left;
    }

    .page-text {
        font-size: 22px;
        line-height: 28px;
        text-align: left;
    }

    .contact-header {
        padding-top: 150px;
        height: 414px;
    }

    .contact-form-section {
        margin-top: -170px;
        justify-content: space-between;
        padding: 80px 15%;
        
    }


    .help-you,
    .question-help,
    .do-you-want {
        text-align: center;
        width: 100%;
    }

    .help-you {
        font-size: 40px;
        line-height: 60px;
    }

    .question-help {
        font-size: 24px;
        line-height: 36px;
        margin-top: 0;
    }

    .do-you-want {
        font-weight: 700;
        font-size: 23px;
        line-height: 34px;
    }


    input[type=text],
    input[type=email],
    input[type=number],
    .contact-submit {
        width: 100%;
        height: 39px;
        border-radius: 4px;
    }

    .icon-sec {
        padding: 0;
    }
    
    .accordion-div {
        flex-direction: row;
    }

    .accordion-div div {
        width: 95%;
    }

    
    .contact-form {
        display: flex;
        justify-content: space-between;
    }

  
}

.text-error {
    text-align: left;
    color: #FF3623;

}