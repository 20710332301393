.payment-container {
    padding-top: 150px;
    background: #FFFFFF;
}

.payment-header-section {
    text-align: center;
}

.payment-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.12em;
    color: #3E2E7F;
    text-transform: uppercase;
}

.paytext-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment-2d-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #CE1D74;
    padding: 20px 0;
}

.missout-el {
    font-size: 18px;
    line-height: 28px;
    color: rgba(102, 102, 102, 0.99);
}

.footer-form input {
    margin: 0 !important;
}

.payment-image {
    width: 90%;
    height: 168px;
    margin: 10px auto 30px;
}

.will-get {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #666666;
    text-align: center;
}

.payment-package {
    width: 90%;
    font-size: 19px;
}

.payment-package ul li {
    font-size: 19px;
    line-height: 29px;
}

.one-select {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #CE1D74
}

@media (min-width:768px) {
    .payment-header-section{
        text-align: left;
    }

    .payment-title {
        font-size: 24px;
        padding-top: 59px;
    }

    .paytext-section {
        flex-direction: row;
    }

    .missout-el {
        max-width: 630px;
        font-size: 19px;
        line-height: 28px;
    }

    .payment-image {
        width: 373px;
        height: 229px;
        margin: 0;
    }

    .payment-package {
        width: 373px;
    }
    
    .one-select {
        font-size: 24px;
        line-height: 36px;
    }

}

.payment-main-section {
    display: flex;
    flex-direction: column;
}

.price-length-value {
    width: inherit;
    display: flex;
    justify-content: center;
    background: #FAFAFA;

}

.price-length-value ul li {
    padding-bottom: 10px;
}

.input-method input {
    margin: 20px;
} 

.payment-card-details {
    margin: 30px auto;
}

.payment-card-details input {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    width: 276px;
}

@media(max-width: 424px){
    .paymet-pricing {
        display: none;
    }
}

@media (min-width:768px){
    .payment-main-section {
        flex-direction: row;
        justify-content: space-between;
    }

    .payment-card-details {
        width: 622px;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }

    .payment-card-details div:first-child {
        margin-right: 30px;
    }
   
}

.order-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.total {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #CE1D74;
}

.secured-payment,
.confirm-and-pay {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
    width: 100%;
    border: 1px solid #E0E0E0;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
}

.confirm-and-pay {
    background: #D43985;
}

.secured-payment {
    background: #F2C2D9;
}

.disclaimer {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: rgba(102, 102, 102, 0.5);
    margin: 25px 0;
}

.disclaimer a {
    text-decoration: none;
    columns: #372E7C;
}

@media (min-width:768px){
    .disclaimer {
        font-size: 18px;
        line-height: 18px;
    }
}