.quotation-title {
    color: #CE1D74;
    text-align: left;
}

.request-quotation{
    margin-top: 200px;
    margin-bottom: 50px;
}

.form-par {
    margin-top: 20px;
    text-align: left !important;
    color: #CE1D74;
    margin-bottom: 20px;
    font-size: 22px !important;
    line-height: 38px;
}

.quote-form {
    /* max-width: 900px; */
    margin: 50px auto;
}

.quote-form-input {
    border: 1px solid #D9D9D9;
    height: 72px !important;
    padding-left: 10px;
}

input[type="radio"] {
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);
}

.quote-row {
    font-size: 20px;
    line-height: 32px;
}


.thank-you-box {
    font-family: 'Montserrat';
    max-width: 616px;
    height: 512px;
    background: #CE1D74;
    color: #FFFFFF;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    padding: .8rem;
    border-radius: 10px;
    z-index: 999;
}

#thank-you-box {
    display: none;
}

.alright-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #CE1D74;
    border: 1px solid #CE1D74;
    border-radius: 4px;
    padding: 15px 60px;
    margin-top: 76px;
}

.icon-mail {
    /* width: 90px !important; */
    background: #CE1D74;
    height: 256px;
    width: 256px;
    left: 192px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    border-radius: 200px;
    margin: -140px auto 0;
}

.env-icon {
    position: absolute;
    top: 35%;
    left: 25%;
}

.thank-you-box h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    padding-top: 64px;
}

.thank-you-box p {
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    max-width: 503px;
    margin: 0 auto;
}

.email-capture {
    color: #F7901F;
    font-weight: 600;
}

.label-terms {
    font-size: 22px;
}

.links {
    color: #CE1D74;
}

.links:hover,
.links:focus {
    color: #84287D;
}

::placeholder {
    opacity: .5; 
}

:disabled {
    background: #DF6CA5;;
}